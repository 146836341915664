
// Run only once
$(document).one('ready',function(){
    console.log("HI");
});
$(document).ready(function () {

    // activate wow.js
    $(function(){
        new WOW().init(); 
    });

    var marker, map_style = [{ "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "transit.line", "elementType": "geometry.fill", "stylers": [{ "color": "#e04e71" }] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#c9c9c9" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }];


    // sticky quick-links nav bar
    var stickyHomeNav = $(".sticky-home");
    var hero = $('.hero').height();
    $(window).scroll(function() {
        if( $(this).scrollTop() > hero + 5 ) {
            stickyHomeNav.addClass("sticky-nav-bar-home");
        } else {
            stickyHomeNav.removeClass("sticky-nav-bar-home");
        }
    });
    var stickyNav = $(".sticky");
    $(window).scroll(function() {
        if( $(this).scrollTop() > 0 ) {
            stickyNav.addClass("sticky-nav-bar");
        } else {
            stickyNav.removeClass("sticky-nav-bar");
        }
    });


    $('[data-toggle="tooltip"]').tooltip();

    // Dropdown menus
    $('.dropdown .dropdown-menu').on('click', '.dropdown-select', function (e) {
        e.preventDefault();
        var link_text = $(this).html(),
            link_val = $(this).data('value');
        $(this).parent().siblings('button').html(link_text);
        $(this).parent().siblings('input[type=hidden]').val(link_val);
    });

    // Dropdown menus with search
    $('.dropdown').on('click', 'button', function() {

        var search = $(this).siblings('.dropdown-menu').find('input[type=search]'),
            opts   = $(this).siblings('.dropdown-menu').find('a');
        setTimeout(function() { search.focus(); }, 300);
        opts.show();

        search.on('keypress', function(e) {

            var keyword = $(this).val();
            opts.hide();
            opts.each(function() {
                if($(this).html().toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
                    $(this).show();
                }
            });

            // If there is only one visible result
            if(opts.filter(':visible').length === 1) {
                var keycode = e.keyCode ? e.keyCode : e.which;
                if(keycode === 13) {
                    var result = opts.filter(':visible').first(),
                        link_text = result[0].innerHTML,
                        link_val = result[0].dataset.value;
                    $(this).parent().siblings('button').html(link_text);
                    $(this).parent().siblings('input[type=hidden]').val(link_val);
                }
            }

        });
    });

    $('.search__link').on('click', function (e) {
        e.preventDefault();
        if ($('form.search-pop').not(':visible')) {
            $('form.search-pop').fadeIn(250);
            $('body').addClass('open');
            $('header nav .search__link').addClass('active');
        }

        $('form.search-pop').on('click', '.search__close', function (e) {
            e.preventDefault();
            $('form.search-pop').fadeOut(200);
            $('body').removeClass('open');
            $('header nav .search__link').removeClass('active');
        });

    });

    // Slide down sections
    $('.slide:not(.no-slide-up) figure').on('click', '> a', function (e) {
        e.preventDefault();
        $(this).find('figcaption').toggleClass('open');
    });

    // Table Active Highlighting
    // $('.has-properties').on('click', function() {
    //     $(this).toggleClass('active');
    // });

    // More filter options drop down
    $('.search-form__more-options--link').on('click', 'a', function (e) {

        e.preventDefault();
        $(this).toggleClass('active');

        var form = $('.search-form__more-options--form');
        if (form.is(':visible')) {
            form.slideUp(500);
        } else {
            form.slideDown(500);
        }

    });


    // Clicking on table row
    // $('.property-search__results--table').on('click', 'tr[data-url]:not(.has-properties)', function(e) {
    //     e.preventDefault();
    //     window.location = $(this).data('url');
    // });

    // $('.property-search__results--table').on('click', 'tr.has-properties', function(e) {
    //     e.preventDefault();
    //     $('tr[id=properties_for_scheme_' + $(this).data('scheme') + ']').toggle().find('.scheme-properties-container').slideToggle();
    // });

    if ($('#property-search__map').length) {

        var lat = parseFloat($('#property-search__map').data('lat')),
            lng = parseFloat($('#property-search__map').data('lng')),
            point = { lat: lat, lng: lng },
            bounds = new google.maps.LatLngBounds();

        var results_map = new google.maps.Map(document.getElementById('property-search__map'), {
            center: point,
            zoom: 15,
            scrollwheel: false,
            styles: map_style
        });

        var markers = $('#property-search__map').data('markers'),
            infowindow = new google.maps.InfoWindow();

        // For each marker
        Object.keys(markers.results).map(function (key) {

            var data = markers.results[key];

            marker = new google.maps.Marker({
                position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
                map: results_map,
                title: data.name,
                icon: data.icon,
                anchor: { x: 15, y: 15 },
            });

            marker.addListener('click', function () {
                infowindow.setContent('<img class="map-pop-image" src="' + data.image + '"><div class="map-pop-text"><br><strong>' + data.name + '</strong><br>' + data.town + ', ' + data.postcode + '<br>' + '<a href="' + data.url + '">More information</a></div>');
                infowindow.open(results_map, this);
            });

            bounds.extend(marker.position);

        });

        // don't fit zoom to bounds on single properties
        if (Object.keys(markers.results).length > 1) {
            results_map.fitBounds(bounds);
        } else {
            results_map.setCenter(marker.position);
            results_map.setZoom(15);
        }

        google.maps.event.addListener(results_map, 'bounds_changed', function () {
            google.maps.event.trigger(results_map, 'resize');
        });


    }

    // Scheme page map
    if ($('#scheme__map').length) {

        var lat = parseFloat($('#scheme__map').data('lat')),
            lng = parseFloat($('#scheme__map').data('lng')),
            point = { lat: lat, lng: lng },
            bounds = new google.maps.LatLngBounds();

        var results_map = new google.maps.Map(document.getElementById('scheme__map'), {
            center: point,
            zoom: 15,
            scrollwheel: false,
            styles: map_style
        });

        marker = new google.maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: results_map,
            title: 'This Property',
            // icon: '/images/teal-marker.png',
        });
    }

    function validateMinMax(min, max) {
        if ($(min).val() > $(max).val()) {
            $(min).addClass('error').after("<span class='input-error'>Min cannot be higher than max</span>");
            return false;
        }
        return true;
    }

    // Perform quick validation on min-max fields on property search
    $('.search-pop').submit(function (e) {
        $('.input-error').hide();
        $('.form-control').removeClass('error');

        if (validateMinMax($('.min-size'), $('.max-size')) && validateMinMax($('.min-rent-price'), $('.max-rent-price'))) {
        } else {
            e.preventDefault();
        }

    });

    // ...Start typewriter on load
    $(function () {
        $('#typewriter0').textillate();
    })

    // ...Start typewriter on carousel slide
    $('#heroCarousel').bind('slide.bs.carousel', function (e) {
        $(function () {
            $('#typewriter0').textillate('start')
            $('#typewriter1').textillate('start')
            $('#typewriter2').textillate('start')
        })
    });

});
